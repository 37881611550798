import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center bg-white rounded-lg p-8 border-2 border-gray-300" }
const _hoisted_2 = { class: "space-y-8 divide-y divide-gray-200 sm:space-y-5" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_4 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_5 = { class: "flex flex-col gap-4 sm:gap-0" }
const _hoisted_6 = { class: "pt-5" }
const _hoisted_7 = { class: "flex justify-end" }
const _hoisted_8 = {
  type: "submit",
  class: "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScaleHeader = _resolveComponent("ScaleHeader")!
  const _component_ScaleRadio = _resolveComponent("ScaleRadio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "space-y-8 divide-y divide-gray-200",
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('AdHdSeverityScale.Title')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t(`AdHdSeverityScale.SubTitle${_ctx.lifeStage}`)), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ScaleHeader, { headers: _ctx.headers }, null, 8, ["headers"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.severityScaleTreatment.items), (item, qidx) => {
              return (_openBlock(), _createBlock(_component_ScaleRadio, {
                key: qidx,
                text: _ctx.$t(`AdHdSeverityScale.Question${_ctx.lifeStage}${item}`),
                modelValue: _ctx.severityScaleTreatment.items[item],
                "onUpdate:modelValue": ($event: any) => ((_ctx.severityScaleTreatment.items[item]) = $event)
              }, null, 8, ["text", "modelValue", "onUpdate:modelValue"]))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back())),
            type: "button",
            class: "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }, _toDisplayString(_ctx.$t('AdHdSeverityScale.Back')), 1),
          _createElementVNode("button", _hoisted_8, _toDisplayString(_ctx.$t('AdHdSeverityScale.Save')), 1)
        ])
      ])
    ], 32)
  ]))
}