
import Scale from '@/models/Scale'
import Patient from '@/models/Patient'
import ScaleHeader from '@/components/ScaleHeader.vue'
import ScaleRadio from '@/components/ScaleRadio.vue'
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'
import LifeStage from '@/enums/LifeStage'

@Options({
  components: {
    ScaleHeader,
    ScaleRadio
  },
  methods: mapActions([
    'downloadAdhdSeverityScaleTreatment',
    'updateAdhdSeverityScaleTreatment'
  ]),
  computed: mapGetters(['patient', 'severityScaleTreatment', 'lifeStage'])
})
export default class PatientData extends Vue {
  severityScaleTreatment!: Scale
  patient!: Patient
  lifeStage!: LifeStage
  headers: Array<string> = []

  // Actions
  updateAdhdSeverityScaleTreatment!: (payload: {
    id: number
    severityScaleTreatment: Scale
  }) => Promise<void>

  downloadAdhdSeverityScaleTreatment!: () => Promise<void>

  async created (): Promise<void> {
    await this.downloadAdhdSeverityScaleTreatment()

    this.headers = [
      this.$t('AdHdSeverityScale.HeaderNever'),
      this.$t('AdHdSeverityScale.HeaderSometimes'),
      this.$t('AdHdSeverityScale.HeaderOften'),
      this.$t('AdHdSeverityScale.HeaderVeryOften')
    ]
  }

  async submit (): Promise<void> {
    try {
      await this.updateAdhdSeverityScaleTreatment({
        id: this.patient.id,
        severityScaleTreatment: this.severityScaleTreatment
      })
      this.$router.back()
      // this.$router.push({
      //   name: 'PatientData',
      //   params: { id: this.patient.id }
      // })
    } catch (error) {
      console.log(error)
      // this.$router.push({ name: 'Logout' })
    }
  }
}
